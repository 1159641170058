@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");

:root {
  --drawerWidth: 240px;
  --appWidth: calc(100vw - var(--drawerWidth));
  --navHeadingHeight: 64px;
}

.App {
  text-align: center;

  #body {
    box-sizing: border-box;
    position: relative;
    top: var(--navHeadingHeight);
    min-height: calc(100vh - var(--navHeadingHeight));
    background-color: rgba(0,0,0,0.06);
    
    .app-error-msg {
      box-sizing: border-box;
      position: fixed;
      bottom: -150px;
      left: 0;
      width: 100%;
      border-top: 1px solid black;
      padding: 8px;
      color: white;
      font-weight: 800;
      background-color: rgba(244, 147, 54, 1);
      z-index: 1200;
      transition: 0.3s;
  
      p {
        margin: 10px 0px;
      }
    }

    .app-success-msg {
      box-sizing: border-box;
      position: fixed;
      bottom: -150px;
      left: 0;
      width: 100%;
      border-top: 1px solid black;
      padding: 8px;
      color: white;
      font-weight: 800;
      background-color: #49d825;
      z-index: 1200;
      transition: 0.3s;
  
      p {
        margin: 10px 0px;
      }
    }
  
    .app-visible {
      bottom: 0px;
    }
  }
}

@media only screen and (min-width: 750px) {
  #body {
    left: var(--drawerWidth);
    width: var(--appWidth);
  }
}

@media only screen and (max-width: 400px) {
  #body {
    top: 54px !important;
    min-height: calc(100vh - 54px) !important;
  }
}