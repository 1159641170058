.MuiCollapse-root {
  border-top: 1px solid rgba(0,0,0,0.12);
}

.MuiTableContainer-root {
  overflow-x: hidden !important;
}

input {
  text-align: center;
}

.MuiSelect-root {
  text-align: center;
}

.MuiInputLabel-shrink {
  color: rgba(0,0,0,0.54) !important;
}

.MuiFormHelperText-root {
  hr {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}